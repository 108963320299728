@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.input_dark_mode {
  visibility: hidden;
}

.circle {
  animation: toggleoff 0.4s linear forwards;
}

.input_dark_mode:checked + .label_dark_mode {
  background-color: rgb(127, 127, 139);
}

.input_dark_mode:checked + .label_dark_mode .circle {
  background-color: rgb(255, 255, 255);
  animation: toggleon 0.4s linear forwards;
}

@keyframes toggleon {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(2.25rem);
  }
}

@keyframes toggleoff {
  0% {
    transform: translateX(2.25rem);
  }

  100% {
    transform: translateX(0);
  }
}
/* LOADER BAR */
.loader-bar-anim {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.loader-bar-anim::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 200px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #ffffff 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

/* PERSONNALISATION DE LA SCROLLBAR */
::-webkit-scrollbar {
  border-radius: 10px;
  width: 5px;
  height: 5px;
  background-color: #dfdfdf;
}
